const tipoPlanoNome = {
  CREDITO_PAGAMETADE: "Paga metade",
  CREDITO_INTEGRAL: "Integral",
};

const TipoSimulacaoEnum = {
  CREDITO_INTEGRAL: "CREDITO_INTEGRAL",
  CREDITO_PAGAMETADE: "CREDITO_PAGAMETADE",
  PARCELA_REDUZIDA: "PARCELA_REDUZIDA",
};

const BOT_NAME = {
  MYCON: "MYCON",
  MODO_TURBO: "MODO_TURBO",
  PERFORMANCE: "PERFORMANCE",
  MYCOINS: "MYCOINS",
  LIVE: "LIVE",
  ANIVERSARIO: "ANIVERSARIO",
  ANIVERSARIO_FINAL_DE_SEMANA: "ANIVERSARIO_FINAL_DE_SEMANA",
  FINDE_TURBO: "FINDE_TURBO",
  FINDE: "FINDE",
  CONECTA: "CONECTA",
  CRM: "CRM",
  ABANDONO_IMOVEL: "ABANDONO_IMOVEL",
};

const TipoBotEnum = {
  [BOT_NAME.MYCON]: {
    textChat1:
      "Oi, eu sou o Mycon! Conte comigo para conquistar com o <b>MELHOR PREÇO do mercado. E com taxas a partir de 9,99% por todo o período.</b>",
    textChat2: "Quer conferir? Simule agora e realize seu sonho:",
  },
  [BOT_NAME.MODO_TURBO]: {
    textChat1:
      "Você tem <b>até este domingo</b> para aproveitar essa <b>OFERTA TURBO.</b> Conquiste seu sonho com...",
    textChat2:
      "<b>REDUÇÃO DE 50% do valor das parcelas até contemplar,</b> mais <b>50% de desconto na sua 1ª parcela.</b> Ah! E taxa especial, de <b>13,99% por 9,99%.</b> Simule já:",
  },
  [BOT_NAME.PERFORMANCE]: {
    textChat1:
      "<b>Olá, eu sou o Mycon!</b><br><br> Que tal escolher o jeito de conquistar seu sonho? É isso mesmo, aqui você pode escolher o plano que faça <b>mais sentido para o SEU bolso!</b> 😍🤑",
    textChat2:
      "Não perca as opções pensadas <b>PARA VOCÊ!</b> Clique abaixo e simule seu consórcio agora mesmo:  ",
  },
  [BOT_NAME.ABANDONO_IMOVEL]: {
    textChat1:
      "<b>Olá, eu sou o Mycon!</b><br><br> Quer conquistar <b>sua casa dos sonhos</b> sem sofrer? Então escolha a melhor oferta para o seu bolso!",
    textChat2:
      "Aqui é assim: <b>sem juros, sem entrada e com desconto na 1ª parcela!</b> Simule agora e aproveite essa oportunidade imperdível:  ",
  },
  [BOT_NAME.MYCOINS]: {
    textChat1:
      "Oi, eu sou o Mycon! Conte comigo para conquistar com o <b>MELHOR PREÇO do mercado. E com taxas a partir de 9,99% por todo o período.</b>",
    textChat2:
      "<b>Ahh</b>, e por estar sendo indicado para o Mycon, você ainda vai ganhar 50% de desconto na sua primeira parcela!",
  },
  [BOT_NAME.LIVE]: {
    textChat1:
      "Oi, eu sou o Mycon!<br><br>No mês do meu aniversário é você quem conquista o sonho com o MELHOR preço do mercado! E ainda: <b>sem juros, sem entrada e com oferta especial!</b>",
    textChat2:
      "Quer conferir? Simule agora e comece a construir seu futuro agora mesmo:",
  },
  [BOT_NAME.ANIVERSARIO]: {
    textChat1:
      "Oi, eu sou o Mycon!<br><br>No mês do meu aniversário é você quem conquista o sonho com o MELHOR preço do mercado! E ainda: <b>sem juros, sem entrada e com oferta especial!</b>",
    textChat2:
      "Quer conferir? Simule agora e comece a construir seu futuro agora mesmo:",
  },
  [BOT_NAME.ANIVERSARIO_FINAL_DE_SEMANA]: {
    textChat1:
      "Oi, eu sou o Mycon!<br><br>No mês do meu aniversário é você quem conquista o sonho com o MELHOR preço do mercado! E ainda: <b>sem juros, sem entrada e com oferta especial!</b>",
    textChat2:
      "Quer conferir? Simule agora e comece a construir seu futuro agora mesmo:",
  },
  [BOT_NAME.FINDE_TURBO]: {
    textChat1:
      "Você tem <b>até domingo</b> para aproveitar essa <b>OFERTA TURBO</b>. Conquiste seu sonho com...",
    textChat2:
      "<b>REDUÇÃO DE 50% do valor das parcelas até contemplar,</b> <b>mais 50% de desconto na sua 1ª parcela.</b> Ah! E taxa especial, de <b>13,99% por 9,99%.</b> Simule já:",
  },
  [BOT_NAME.CONECTA]: {
    textChat1:
      "Oi, eu sou o Mycon!</b> Conte comigo para conquistar com o <b>MELHOR PREÇO do mercado. Por aqui, as taxas são a partir de 0,6% ao mês!</b>",
    textChat2: "Quer conferir? Simule agora e realize seu sonho:",
  },
  [BOT_NAME.CRM]: {
    textChat1:
      "Oi, eu sou o Mycon!</b><br>Aqui você garante seu sonho com o MELHOR preço do mercado! E ainda: <b>sem juros, sem entrada e com oferta especial!",
    textChat2:
      "Quer ficar mais perto da conquista? <b>Simule agora e termine 2024 de olho no futuro:</b>",
  },
};

const ProdutosEnum = {
  IMOVEL: "IMOVEL",
  CARRO: "CARRO",
  MOTO: "MOTO",
  SERVICO: "SERVICO",
  CAMINHAO: "CAMINHAO",
  MAQUINAS: "MAQUINAS",
};

const ProdutosNomes = {
  IMOVEL: "IMÓVEL",
  CARRO: "CARRO",
  MOTO: "MOTO",
  SERVICO: "SERVIÇO",
  CAMINHAO: "CAMINHÃO",
  MAQUINAS: "MÁQUINAS",
};

const valoresSimulacao = {
  [TipoSimulacaoEnum.CREDITO_INTEGRAL]: {
    [ProdutosEnum.IMOVEL]: { min: 100000, max: 700000 },
    [ProdutosEnum.CARRO]: { min: 30000, max: 400000 },
    [ProdutosEnum.MOTO]: { min: 10000, max: 120000 },
    [ProdutosEnum.SERVICO]: { min: 12000, max: 20000 },
    [ProdutosEnum.CAMINHAO]: { min: 100000, max: 400000 },
    [ProdutosEnum.MAQUINAS]: { min: 100000, max: 400000 },
  },
  [TipoSimulacaoEnum.CREDITO_PAGAMETADE]: {
    [ProdutosEnum.IMOVEL]: { min: 100000, max: 700000 },
    [ProdutosEnum.CARRO]: { min: 30000, max: 400000 },
    [ProdutosEnum.MOTO]: { min: 10000, max: 120000 },
    [ProdutosEnum.SERVICO]: { min: 0, max: 0 },
    [ProdutosEnum.CAMINHAO]: { min: 100000, max: 400000 },
    [ProdutosEnum.MAQUINAS]: { min: 100000, max: 400000 },
  },
};

const CategoriaPagamento = {
  CREDITO_INTEGRAL: {
    IMOVEL: 1,
    CARRO: 2,
    MOTO: 3,
    SERVICO: 4,
    CAMINHAO: 6,
    MAQUINAS: 6,
  },
  CREDITO_PAGAMETADE: {
    IMOVEL: 20,
    CARRO: 21,
    MOTO: 22,
    SERVICO: 0, // verificar qual é o id
    CAMINHAO: 26,
    MAQUINAS: 26,
  },
  PARCELA_REDUZIDA: {
    IMOVEL: 29,
    CARRO: 30,
    MOTO: 31,
    SERVICO: 0, // verificar qual é o id
    CAMINHAO: 32,
    MAQUINAS: 32,
  },
};

export {
  ProdutosEnum,
  tipoPlanoNome,
  TipoSimulacaoEnum,
  valoresSimulacao,
  CategoriaPagamento,
  TipoBotEnum,
  ProdutosNomes,
  BOT_NAME,
};
